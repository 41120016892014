@import url(https://fonts.googleapis.com/css?family=Ubuntu);
body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Design variables to reuse */
:root {
  --dark: #1d1d1d;
  --bar-color: #181818;
  --primary: #00aaff;
  --grey: #8d8d8d;
}

/* Backgrounds */
body {
  background: #1d1d1d !important;
  background: var(--dark) !important;
}

.bg-dark {
  background: #1d1d1d !important;
  background: var(--dark) !important;
}

.bg-grey {
  background: #8d8d8d !important;
  background: var(--grey) !important;
}

.bg-bar {
  background: #181818 !important;
  background: var(--bar-color) !important;
}

.bg-black__o-80 {
  background: black !important;
  opacity: 0.8 !important;
}

/* Button */
.btn {
  transition: 1s;
}

.btn-outline-primary {
  text-transform: unset !important;
  color: #00aaff !important;
  color: var(--primary) !important;
  background-color: transparent !important;
  border: 2px solid #00aaff !important;
  border: 2px solid var(--primary) !important;
}

.btn-outline-primary:hover {
  color: #007ab7 !important;
  border: 2px solid #007ab7 !important;
  box-shadow: 4px 6px 10px black;
  /* cursor: pointer; */
}

.btn-outline-light:hover {
  box-shadow: 4px 6px 10px black;
  cursor: pointer;
}

/* Text styling */
.color-white {
  color: white !important;
}

.color-grey {
  color: #8d8d8d !important;
  color: var(--grey) !important;
}

a.link {
  color: inherit;
  cursor: pointer;
}

a.link:hover {
  color: #00aaff;
}

/* Positioning */
.p-fixed {
  position: fixed !important;
}

.p-absolute {
  position: absolute !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.top-0 {
  top: 0 !important;
}

.left-0 {
  left: 0 !important;
}

/* Height and width */
.h-full {
  height: 100vh !important;
}

.h-full__all {
  height: 100vh !important;
}

.w-full {
  width: calc(100vw - 60px);
}

.minh-full {
  min-height: 100vh !important;
}

.h-10rem {
  height: 10rem !important;
}

.h-20rem {
  height: 20rem !important;
}

/* z-index */
.z-50 {
  z-index: 50;
}

/* Common page design */
.page-title {
  font-family: "Ubuntu";
  padding: 3rem 0 0 3rem;
}

.section-title {
  font-family: "Ubuntu";
  margin-bottom: 1rem;
  padding: 3rem 0 0 3rem;
}

/* Side bar design */
#side-bar {
  background: #181818;
  background: var(--bar-color);
  width: 60px;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 3;
  min-height: 500px;
}

#side-bar .logo {
  height: 120px;
}

.social-links {
  list-style: none;
  display: flex;
  height: 120px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.social-links a {
  color: white !important;
}

.social-links a:hover {
  color: #00aaff !important;
  color: var(--primary) !important;
}

:not(.nav-menu).list-group a {
  border: none;
  background: transparent !important;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding: 0;
  height: 16px;
}

.list-group .active {
  border: none !important;
  color: #00aaff !important;
  color: var(--primary) !important;
}

.list-group a:hover {
  border: none;
  color: #00aaff;
  color: var(--primary);
}

.app-wrapper {
  padding: 0 0 0 60px !important;
}

:not(.nav-menu).list-group a i {
  display: block;
}

:not(.nav-menu).list-group a:hover i {
  display: none;
}

:not(.nav-menu).list-group a p {
  display: none;
}

:not(.nav-menu).list-group a:hover p {
  display: block;
  color: #00aaff;
  color: var(--primary);
  margin: -10px;
  font-size: 10px;
}

/* Home page */
.home-page {
  background: url(/static/media/designer.0a40cb70.png);
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}

.home-page__start {
  display: flex;
  align-items: center;
  background: url(/static/media/any.3d907205.png);
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
}

.home-page__start h1 {
  color: #fff;
  font-size: calc(32px + 2vw);
  line-height: calc(45px + 2vw);
  margin: 0;
  font-weight: normal;
  font-family: "Ubuntu";
}

.home-page__start h1:hover {
  cursor: default;
}

.home-page__start h1 span {
  transition: 1s;
}

.contact-link:hover {
  padding-bottom: 5px;
  border-bottom: 3px solid;
}

.img-col {
  transition: 1s;
}

.img-col__left {
  padding: 1rem 2rem 1rem 0;
}

.img-col__right {
  padding: 1rem 0 1rem 2rem;
}

.img-title {
  padding: 1rem;
  color: white;
  font-weight: 600 !important;
  font-family: "Ubuntu";
}

/* Projects */
.project-img {
  height: 80vh;
}

/* .img-col .mask:hover {
  cursor: pointer !important;
} */

.project-img.img-gruppkort {
  background: url(/static/media/gruppkort.3982d736.jpg);
  background-position: center;
  background-size: cover;
}

.project-img.img-1 {
  background: url(/static/media/harald.9c369bb5.png);
  background-position: center;
  background-size: cover;
}

.project-img.img-2 {
  background: url(/static/media/skiftschema.9bfe4d1a.png);
  background-position: center;
  background-size: cover;
}

.project-img.img-3 {
  background: url(/static/media/pixels.59ee6f94.png);
  background-position: center;
  background-size: cover;
}

.project-img.img-4 {
  background: url(/static/media/ava-stories.a8491fd3.jpg);
  background-position: center;
  background-size: cover;
}

.project-img.img-5 {
  background: url(/static/media/hugobeck.c6758ccf.png);
  background-position: center;
  background-size: cover;
}

.project-button {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem;
}

/* Instagram Cards */
.ig-card__1 {
  padding-left: 0;
  padding-right: 2rem;
}

.ig-card__2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.ig-card__3 {
  padding-left: 2rem;
  padding-right: 0;
}

.ig-card .card-body {
  background: #1d1d1d;
  background: var(--dark);
  color: white;
}

.ig-card .mask:hover {
  cursor: pointer;
}

.ig-card .loading-div {
  height: 20rem;
  background: #1d1d1d;
  background: var(--dark);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.post-info .info {
  color: #6f6f6f;
  font-size: 0.7rem;
  margin-bottom: 0;
}

.post-info .username {
  font-size: 0.7rem;
}

.post-info .username a {
  color: white;
}

.post-info .username a:hover {
  color: #00aaff;
  color: var(--primary);
}

/* Navbar */
nav {
  height: 60px;
  align-items: center;
  display: flex;
  padding: 1rem;
  color: white;
  position: fixed;
  background: #181818;
  background: var(--bar-color);
  width: 100vw;
  z-index: 40;
}

.nav-menu p {
  margin-bottom: 0;
  margin-left: 1rem;
}

.nav-menu.list-group a {
  border: none;
  background: transparent !important;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding: 0;
  padding-left: 1rem;
  height: 16px;
}

/* Footer */
.footer {
  margin-left: 60px;
  min-height: 10rem;
  width: auto;
  color: white;
}

.footer div div {
  padding: 3rem;
}

.footer-title {
  font-family: "Ubuntu";
  margin-bottom: 1rem;
  padding: 0;
}

.btn-mail {
  padding: 0;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 18.5px;
  transition: 1s;
  margin: 2rem 0;
}

.btn-mail span {
  display: contents;
  color: transparent;
  transition: 0s;
}

.btn-mail:hover {
  width: 140px;
}

.btn-mail:hover i {
  margin-right: 5px;
}

.btn-mail:hover span {
  color: white;
  transition: 3s;
}

/* Map */
.map-div {
  height: 40vh;
}

.map-div div div {
  width: calc(100vw - 90px) !important;
}

.gmnoprint div {
  background-color: transparent !important;
  box-shadow: none !important;
}

.gmnoprint.gm-bundled-control {
  top: 0 !important;
  left: 0 !important;
}

/* Contact */
.address {
  color: white;
  list-style: none;
  padding-left: 3rem;
}

.contact-page .form-group {
  color: white;
  padding: 1.5rem 3rem;
}

.md-form input,
.md-form textarea {
  border-bottom: 1px solid white !important;
  color: white;
}

.md-form input:focus,
.md-form textarea:focus {
  border-bottom: 1px solid #00aaff !important;
  border-bottom: 1px solid var(--primary) !important;
  box-shadow: 0 1px 0 0 #00aaff !important;
  box-shadow: 0 1px 0 0 var(--primary) !important;
  color: white;
}

.md-form label.active {
  color: #00aaff !important;
  color: var(--primary) !important;
}

.md-form i.active {
  color: #00aaff !important;
  color: var(--primary) !important;
}

.form-group .btn {
  margin-left: 3.5rem;
  margin-right: 1rem;
}

/* About */
.about p {
  padding-left: 3rem;
  padding-right: 3rem;
}

/* Skills */
.skills p {
  padding-left: 3rem;
  padding-right: 3rem;
}

ul {
  margin: 0;
}
ul.dashed {
  list-style-type: none;
}
ul.dashed > li {
  text-indent: -5px;
}
ul.dashed > li:before {
  content: "-";
  margin-right: 10px;
  text-indent: -5px;
}

/* Small screens */
@media screen and (max-width: 768px) {
  .minh-full {
    min-height: calc(100vh - 60px) !important;
  }

  .h-full {
    height: calc(100vh - 60px) !important;
  }

  .w-full {
    width: 100vw;
  }

  .app-wrapper {
    padding: 60px 0 0 0 !important;
  }

  .home-page {
    background: transparent !important;
  }

  .home-page__start {
    align-items: center;
    justify-content: center;
    text-align: center;
    background-position: top;
  }

  .home-page__start div {
    padding-left: 0 !important;
  }

  h1.contact-link {
    font-size: 2rem;
  }

  .footer {
    margin-left: 0;
    width: 100vw;
  }

  /* Projects */
  .section-title {
    margin-bottom: 1rem;
    padding: 2rem 0 0 3rem;
  }

  .img-col__left {
    padding: 2rem 0;
  }

  .img-col__right {
    padding: 2rem 0;
  }

  /* Map */
  .map-div div div {
    width: 100% !important;
  }

  .img-title {
    font-size: 18px;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .img-text {
    font-size: 12px;
  }
}

/* Small and medium screens */
@media screen and (max-width: 992px) {
  .ig-card {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
}

/* Width less than height */
@media screen and (max-aspect-ratio: 1/1) {
  .home-page {
    background-position: top !important;
  }

  .project-img.img-gruppkort {
    background: url(/static/media/gruppkort-mobile.6e241551.jpg);
    background-position: center;
    background-size: cover;
  }
}

